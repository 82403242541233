import { useEffect, useRef, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";


import LoadingWheel from "../../components/shared/LoadingWheel";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import useQuotes from "../../data/useQuotes";
import Modal from "../../layouts/Modal";
import { IQuote } from "../../models/Quote";
import QuotesTable from "../../components/quotes/quotesTable";


export default function Quotes() {
    const { setInfo } = usePageNameContext();
    const { abandoned } = useQuotes();
    const [searchParams] = useSearchParams();
    const [quotes, setQuotes] = useState<IQuote[] | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState<string>("");

    useEffect(() => {
        setInfo({
            name: "Abandoned Quotes",
            desc: "View and manage your abandoned quotes here",
        });
    }, []);

    useEffect(() => {
        if (!abandoned.isLoading) { // && !unreadMessages.isLoading) {
            let filter = abandoned.data?.data;

            filter = filter?.filter((quote) => {
                if (searchTerm === "") {
                  return true;
                } else {
                    return (
                        quote.vehicleMake.toLowerCase().includes(searchTerm) ||
                        quote.vehicleModel.toLowerCase().includes(searchTerm) ||
                        quote.year.toString().includes(searchTerm) ||
                        quote.vehicleRegistration.toLowerCase().includes(searchTerm) ||
                        quote.postcode?.toLowerCase().includes(searchTerm)
                    );
                }
            });

            // sort by date
            filter = filter?.sort((a, b) => {
                if (a.createdDate < b.createdDate) {
                    return 1;
                } else if (a.createdDate > b.createdDate) {
                    return -1;
                } else {
                    return 0;
                }
            });

            setQuotes(filter);
            setIsLoading(false);
        }
    }, [
        abandoned.isLoading, 
        abandoned.data,
        searchTerm
    ]);

    return (
        <>
            <div className="mt-2 flex justify-between">
                <div className="flex gap-x-5"> 
                    <div className="relative flex items-stretch flex-grow focus-within:z-10">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <MagnifyingGlassIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </div>
                        <input
                        type="text"
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="pl-10 sm:text-sm"
                        placeholder="Name, VRM or Postcode"
                        />
                    </div>
                </div>
            </div>

            <div className="flex gap-4 mt-4">
                <div className="bg-white px-4 py-2 rounded-lg shadow flex items-center">
                    1: Confirm <div className="rounded-full bg-gray-200 h-4 w-4 ml-4"></div>
                </div>
                <div className="bg-white px-4 py-2 rounded-lg shadow flex items-center">
                    2: Engine <div className="rounded-full bg-gray-200 h-4 w-4 ml-4"></div>
                </div>
                <div className="bg-white px-4 py-2 rounded-lg shadow flex items-center">
                    3: Body <div className="rounded-full bg-gray-200 h-4 w-4 ml-4"></div>
                </div>
                <div className="bg-white px-4 py-2 rounded-lg shadow flex items-center">
                    4: Prices <div className="rounded-full bg-gray-200 h-4 w-4 ml-4"></div>
                </div>
                <div className="bg-white px-4 py-2 rounded-lg shadow flex items-center">
                    5: Contact <div className="rounded-full bg-orange-200 h-4 w-4 ml-4"></div>
                </div>
                <div className="bg-white px-4 py-2 rounded-lg shadow flex items-center">
                    6: Personal <div className="rounded-full bg-yellow-200 h-4 w-4 ml-4"></div>
                </div>
                <div className="bg-white px-4 py-2 rounded-lg shadow flex items-center">
                    7: Payment <div className="rounded-full bg-green-200 h-4 w-4 ml-4 "></div>
                </div>
            </div>

            {abandoned.isLoading || isLoading ? (
                <div className="mt-8 min-h-[50vh] my-4 p-4 bg-white rounded-lg shadow">
                    <LoadingWheel />
                </div>
            ) : (
                <>
                    <QuotesTable data={quotes!} />
                </> 
            )}
        </>
    );
    
          
}