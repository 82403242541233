import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import usePriceSettings from "../../data/usePriceSettings";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { ICustomerPriceConfiguration } from "../../models/PriceSettings";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import SwitchInput from "../shared/Inputs/SwitchInput";
import GeneralPriceSettingSingle from "./PriceConfiguration/GeneralPriceSettingSingle";
import LoadingWheel from "../shared/LoadingWheel";
import CatalyticRules from "./GeneralConfiguration/CatalyticRules";
import ExportRules from "./GeneralConfiguration/ExportRules";
import VfeExportRules from "./GeneralConfiguration/VfeExportRules";
import { NumberInput } from "../shared/Inputs/NumberInput";

export default function GeneralPriceSettings() {
  const { priceSettings, updateConfig } = usePriceSettings();
  const { saveHandlers } = useDefaultCRUDHandlers("Price Settings");
  const [isLoading, setIsLoading] = useState(true);
  const { register, handleSubmit, watch, setValue, getValues, reset } =
    useForm<ICustomerPriceConfiguration>({
      defaultValues: {
        id: "",
        doesCollection: false,
        doesDropOff: false,
        collectionPriceSetting: { value: 0, isFixed: true },
        dropOffPriceSetting: { value: 0, isFixed: true },
        alloyWheelsPriceSetting: { value: 0, isFixed: true },
        noFaultPriceSetting: { value: 0, isFixed: true },

        startsAndDrivesPriceSetting: { value: 0, isFixed: true },

        maxSalvageMileage: 0,

        engineExportThreshold: 0,
        vfeExportThreshold: 0,
        // catalyticConverterPriceSetting: { value: 0, isFixed: true }
        // noFaultPriceSetting: { value: 0, isFixed: true },
      },
    });

  const ensureBooleanIsFixed = (setting: any) => ({
    ...setting,
    isFixed:
      setting?.isFixed === "true" || setting?.isFixed === true ? true : false, //convert explicit boolean to boolean because react hook form is a bitch
  });

  const onSubmit = (data: any) => {
    console.log(data);

    data = {
      ...data,
      collectionPriceSetting: ensureBooleanIsFixed(data.collectionPriceSetting),
      dropOffPriceSetting: ensureBooleanIsFixed(data.dropOffPriceSetting),
      alloyWheelsPriceSetting: ensureBooleanIsFixed(data.alloyWheelsPriceSetting),
      // noFaultPriceSetting: ensureBooleanIsFixed(data.noFaultPriceSetting),
      catalyticConverterPriceSetting: ensureBooleanIsFixed(data.catalyticConverterPriceSetting),
      startsAndDrivesPriceSetting: ensureBooleanIsFixed(data.startsAndDrivesPriceSetting)
    };

    console.log(data);

    updateConfig.mutate(data, {
      ...saveHandlers,
      onSuccess: () => {
        saveHandlers.onSuccess();
        setValue("collectionPriceSetting.isFixed", data.collectionPriceSetting.isFixed);
        setValue("dropOffPriceSetting.isFixed", data.dropOffPriceSetting.isFixed);
        setValue("alloyWheelsPriceSetting.isFixed", data.alloyWheelsPriceSetting.isFixed);
        setValue("startsAndDrivesPriceSetting.isFixed", data.startsAndDrivesPriceSetting.isFixed);
      }
    });
  };

  const doesDropOff = watch("doesDropOff");

  useEffect(() => {
    if (!priceSettings.isLoading) {
      if (priceSettings.data?.data.customerPriceConfiguration && isLoading) {
        reset(priceSettings.data?.data.customerPriceConfiguration);
        setIsLoading(false);
      }
    }
  }, [priceSettings.data, priceSettings.isLoading]);

  if (isLoading) return <LoadingWheel />;

  return (
    <div className="space-y-4">

      <div className="bg-white shadow rounded p-4">
        <h1 className="text-xl font-semibold">General</h1>
        <hr />

        <form
          className="py-4 gap-4 grid grid-cols-1 lg:grid-cols-3"
          onSubmit={handleSubmit(onSubmit)}
        >
          <SwitchInput
            label={"Do you collect?"}
            registerName={"doesCollection"}
            register={register}
            setValue={setValue}
            watch={watch}
          />
          <SwitchInput
            label={"Do you allow drop offs?"}
            registerName={"doesDropOff"}
            register={register}
            setValue={setValue}
            watch={watch}
          />

          <h1 className="lg:col-span-3 mt-4 text-lg font-semibold">
            How much extra value do these add?
          </h1>

          {/* <GeneralPriceSettingSingle
            label={"Collection"}
            objectName={"collectionPriceSetting"}
            register={register}
            setValue={setValue}
            watch={watch}
          /> */}
          <div className={`flex items-center gap-x-4`}>
            <NumberInput label={"Drop Off"} registerName={"dropOffPriceSetting.value"} register={register} classes="w-32 lg:w-64" minVal={0} />
            
            <input
              type="radio"
              className="hidden"
              {...register("dropOffPriceSetting.isFixed")}
              value={"true"}
              checked={true}
            />
          </div>
          {/* <GeneralPriceSettingSingle
            label={"Drop Off"}
            objectName={"dropOffPriceSetting"}
            register={register}
            setValue={setValue}
            watch={watch}
            minVal={0}
            disabled={!doesDropOff}
          /> */}
          <GeneralPriceSettingSingle
            label={"Alloy Wheels"}
            objectName={"alloyWheelsPriceSetting"}
            register={register}
            setValue={setValue}
            watch={watch}
            minVal={0}
          />

          <GeneralPriceSettingSingle
            label={"Starts and Drives"}
            objectName={"startsAndDrivesPriceSetting"}
            register={register}
            setValue={setValue}
            watch={watch}
            minVal={0}
          />

          <div className={`flex items-center gap-x-4`}>
            <NumberInput label={"Max Salvage Mileage"} registerName={"maxSalvageMileage"} register={register} classes="w-32 lg:w-64" minVal={0} />
          </div>

          <div className={`flex items-center gap-x-4`}>
            <NumberInput label={"Engine Export Threshold"} registerName={"engineExportThreshold"} register={register} classes="w-32 lg:w-64" minVal={0} />
          </div>

          <div className={`flex items-center gap-x-4`}>
            <NumberInput label={"Vfe Export Threshold"} registerName={"vfeExportThreshold"} register={register} classes="w-32 lg:w-64" minVal={0} />
          </div>
          
          {/* <GeneralPriceSettingSingle label={"No Fault"} objectName={"noFaultPriceSetting"} register={register} setValue={setValue} watch={watch} /> */}
          {/* <GeneralPriceSettingSingle label={"Catalytic Converter"} objectName={"catalyticConverterPriceSetting"} register={register} setValue={setValue} watch={watch} /> */}

          {/* <div> Spacer </div> */}

          <div className="flex lg:col-span-3 items-center pt-7 w-full">
            <ButtonInput label="Save" isSubmit />
          </div>
        </form>
      </div>

      <CatalyticRules />

      <ExportRules />

      <VfeExportRules />

    </div>
  );
}
