import { useForm } from "react-hook-form";
import GeneralPriceSettingSingle from "../PriceConfiguration/GeneralPriceSettingSingle";
import { ICustomerSalvagePriceSetting } from "../../../models/PriceSettings";
import { ButtonInput } from "../../shared/Inputs/ButtonInput";
import { useEffect, useState } from "react";
import usePriceSettingsMutations from "../../../data/usePriceSettingsMutations";
import useDefaultCRUDHandlers from "../../../hooks/useDefaultCRUDHandlers";



export default function SingleSalvagePrice({ label, salvagePriceSetting, disabled }: { label: string, salvagePriceSetting?: ICustomerSalvagePriceSetting, disabled?: boolean }) {
  const { updatePriceSetting } = usePriceSettingsMutations();
  const { saveHandlers } = useDefaultCRUDHandlers("Salvage Price Setting");
  const [isLoading, setIsLoading] = useState(true);
  const { handleSubmit, register, setValue, watch, reset } = useForm<ICustomerSalvagePriceSetting>({
    defaultValues: {
      id: salvagePriceSetting?.id || "",
      priceSetting: {
        id: salvagePriceSetting?.priceSetting?.id || "",
        value: salvagePriceSetting?.priceSetting?.value || 0,
        isFixed: salvagePriceSetting?.priceSetting?.isFixed || true
      }
    }
  });

  const ensureBooleanIsFixed = (setting: any) => ({
    ...setting,
    isFixed:
      setting?.isFixed === "true" || setting?.isFixed === true ? true : false, //convert explicit boolean to boolean because react hook form is a bitch
  });

  const onSubmit = (data: any) => {
    //Update the price setting
    const body = ensureBooleanIsFixed(data.priceSetting);

    updatePriceSetting.mutate(body, saveHandlers);
  };

  useEffect(() => {
    if (salvagePriceSetting && isLoading) {
      reset(salvagePriceSetting);
      setIsLoading(false);
    }
  }, [salvagePriceSetting])



  if (isLoading) return null;

  if (disabled) return (
    <div className="flex items-center gap-x-4 pointer-events-none opacity-50">
      <GeneralPriceSettingSingle label={label} objectName={"priceSetting"} register={register} setValue={setValue} watch={watch} minVal={null} />
      <ButtonInput label="Save" isSubmit classes="mt-7 opacity-0" />
    </div>
  )

  return (
    <form className="flex items-center gap-x-4" onSubmit={handleSubmit(onSubmit)}>
      <GeneralPriceSettingSingle label={label} objectName={"priceSetting"} register={register} setValue={setValue} watch={watch} minVal={null} />
      <ButtonInput label="Save" isSubmit classes="mt-7" />
    </form>
  )
}