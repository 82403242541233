import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { DefaultResponseWithData } from "../models/system";

export interface IUkvdResponse {
    make: string;
    range: string | null;
}

export default function useUkvd() {
    const { get } = useApiHelper();
    const queryClient = useQueryClient();

    const makes = useQuery({
        queryKey: ["UkvdMakes"],
        queryFn: () => get<DefaultResponseWithData<IUkvdResponse[]>>(`/vehicle/makes`),
        refetchInterval: 1000 * 60,
    });

    

    return {
        makes,
    };

}