import { useEffect, useState } from "react";
import classNames from "../../utils/classNames";
import { IPostcodePrice } from '../../models/PostcodeGroup';
import usePostcodePriceMutations from "../../data/usePostcodePriceMutations";
import useDefaultCRUDHandlers from '../../hooks/useDefaultCRUDHandlers';


export default function EditPostcodeAreaThresholdPrice({ postcodeArea }: { postcodeArea: IPostcodePrice }) {
  const { update } = usePostcodePriceMutations();
  const { saveHandlers, deleteHandlers } = useDefaultCRUDHandlers("Postcode Area");
  const [thresholdPrice, setThresholdPrie] = useState(postcodeArea.thresholdPrice);

  useEffect(() => {
    setThresholdPrie(postcodeArea.thresholdPrice);
  }, [postcodeArea.thresholdPrice]);

  const handlePriceChange = (e: React.FocusEvent<HTMLInputElement>) => {
    if (postcodeArea.thresholdPrice === parseFloat(e.target.value)) return;

    update.mutate({
      ...postcodeArea,
      thresholdPrice: parseFloat(e.target.value)
    }, {
      ...saveHandlers
    });
  }


  return (
    <div className={classNames("sm:col-span-1")}>
      <input
        id={postcodeArea.id}
        value={thresholdPrice}
        onChange={(e) => setThresholdPrie(parseFloat(e.target.value))}
        type="number"
        step="0.01"
        onBlur={handlePriceChange}
        className="w-full mt-1 text-sm text-gray-900 border-1 border-gray-400"
      />
    </div>
  )
}